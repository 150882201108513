// assets
import { useState } from "react";
import Sparkle from "../../assets/icons/sparkle.svg";

import AMI6 from "../../assets/images/archive-ami/jihan-duerme-archive-ami-06.jpg";

import Othership1 from "../../assets/images/archive-othership/jihan-duerme-archive-othership-01.png";
import Othership2 from "../../assets/images/archive-othership/jihan-duerme-archive-othership-02.png";
import Othership3 from "../../assets/images/archive-othership/jihan-duerme-archive-othership-03.png";
import Othership4 from "../../assets/images/archive-othership/jihan-duerme-archive-othership-04.png";
import Othership5 from "../../assets/images/archive-othership/jihan-duerme-archive-othership-05.png";
import Othership6 from "../../assets/images/archive-othership/jihan-duerme-archive-othership-06.png";

function Archive() {
    const [osIndex, setOsIndex] = useState(0);
    const osImages = [Othership2, Othership1, Othership3, Othership5, Othership4, Othership6]

    const showPrevOsImage = () => {
        setOsIndex((prevIndex) => (prevIndex - 1 + osImages.length) % osImages.length);
    };

    const showNextOsImage = () => {
        setOsIndex((prevIndex) => (prevIndex + 1) % osImages.length);
    };

    return (
        <section className="design-projects">
            {/* Section Title */}
            <header className="d-flex mb-4 mb-md-5">
                <img src={Sparkle} alt="Sparkle" />
                <h2>Product Design Work</h2>
            </header>
            <section className="design-project-content">
                {/* Project 1 */}
                <section className="archive-items">
                    <div className="archive-images">
                        <img src={AMI6} alt="AM Insights App Design" />
                    </div>
                    <article>
                        <h3>My design served as the foundation for a fund data app, both on web and mobile platforms.</h3>
                        <p>AM Insights is an app for asset and wealth managers, offering a personalized dashboard to look up, compare, and track funds, set alerts, and more. My role spanned the entire project, from conducting research to devising feasible solutions that meet industry standards and enhance usability, and performming user testing before handing it off to engineering. <a href="mailto:jihan.duerme@gmail.com">A detailed case study is available upon request</a></p>
                        <div className="archive-chip">
                            <p>User Research</p>
                            <p>Journey Maps</p>
                            <p>User Flow Design</p>
                            <p>Problem Solving</p>
                            <p>Wireframes</p>
                            <p>High-fidelity Prototypes</p>
                            <p>Design System</p>
                            <p>Illustrations</p>
                            <p>User Feedback Iteration</p>
                        </div>
                        <div className="archive-buttons">
                            <div>
                            </div>
                            <a href="mailto:jihan.duerme@gmail.com">A detailed case study is available upon request</a>
                        </div>
                    </article>
                </section>

                {/* Project 2 */}
                <section className="archive-items">
                    <div className="archive-images os-carousel">
                        <img src={osImages[osIndex]} alt="Othership App Design" onClick={showNextOsImage} />
                    </div>
                    <article>
                        <h3>I created a design for onboarding, dashboard, and booking interfaces for two distinct user types.</h3>
                        <p>Othership provides software solutions for professionals and workspace hosts. My role was to design an experience that enhanced communication between users and workspace venue hosts. This project involved developing hundreds of interaction flows for a management system to handle bookings and scheduling efficiently, streamlining user-host interactions. <a href="mailto:jihan.duerme@gmail.com">Get in touch for more details</a></p>
                        <div className="archive-chip">
                            <p>User Research</p>
                            <p>User Flow Design</p>
                            <p>Problem Solving</p>
                            <p>Wireframes</p>
                            <p>High-fidelity Prototypes</p>
                            <p>User Feedback Iteration</p>
                        </div>
                        <div className="archive-buttons">
                            <div>
                                <button type="button" className="arrow arrow-prev" onClick={showPrevOsImage}>
                                    <i className="bi bi-arrow-left"></i>
                                </button>
                                <button type="button" className="arrow arrow-next" onClick={showNextOsImage}>
                                    <i className="bi bi-arrow-right"></i>
                                </button>
                            </div>
                            <a href="mailto:jihan.duerme@gmail.com">Get in touch for more details</a>
                        </div>
                    </article>
                </section>
            </section>
        </section>
    );
};

export default Archive;